.connect{

    margin-top: -2%;
    margin-bottom: -2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 3pt solid red; */
}




.form{
    box-shadow: 3px 3px 5px 5px rgb(112, 111, 111);
    /* border: 3pt solid blue; */
    margin-top: 3%;

}

.map-box{
    
    /* border: 3pt solid red; */

}


.connect-container{
    margin-top: -9%;
    margin-bottom: -5%;
}

.enter-name{
    transition: transform 0.6s ease, background-color 0.9s ease; 
}

.enter-name:hover{
    transform: scale(1.10) translateX(3rem);
    background-color: rgb(151, 151, 241);
}


.enter-email{
    transition: transform 0.6s ease, background-color 0.9s ease; 
}

.enter-email:hover{
    transform: scale(1.10) translateX(3rem);
    background-color: rgb(241, 177, 151);
}


.enter-message{
    transition: transform 0.6s ease, background-color 0.9s ease; 
}

.enter-message:hover{
    transform: scale(1.10) translateX(3rem);
    background-color: rgb(117, 211, 228);
}
.enter-phone{
    transition: transform 0.6s ease, background-color 0.9s ease; 
}

.enter-phone:hover{
    transform: scale(1.10) translateX(3rem);
    background-color: rgb(209, 212, 14);
}