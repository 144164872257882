.foot{
    /* background-image: url('../../assets/logo\ 8.png');
        background-size: cover; */

margin-top: 18rem;
padding-bottom: 1%;

}

.footerLogo{
    height: 100px;
    width: 200px;
}

p{
    font-size: large;
}

.follow{
    margin-left: 17%;
}

.mini_map{
    width: 300px;
    height: 600px;
    
}