.card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  .card-container {
  transition: transform 0.6s ease, background-color 0.9s ease; /* Added ease for background-color */
}

.card-container:hover {
  transform: scale(1.27);
  background-color: rgb(207, 30, 30);
}


  
  .card-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .card-container {
    flex: 1 1 300px; /* Adjust the width as needed */
    margin: 10px;
  }
  
  @media (max-width: 768px) {
    .card-container {
      flex: 1 1 250px; /* Adjust the width for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .card-container {
      flex: 1 1 200px; /* Adjust the width for even smaller screens */
    }
  }
  