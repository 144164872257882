.heading{
    margin-top: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
/* 
.main-gallery{
    border: 3pt solid blue;
    
} */