.navBlock {
    width: 100%;
    height: auto; /* Adjust height to auto for responsiveness */
    min-height: 120vh; /* Ensure minimum height of 100vh */
    display: flex;
   
    background-size: cover;
    background-position: center;
    animation: slideshow 20s infinite; /* Adjust timing and iteration as needed */
   
    min-height: 100vh; /* Ensure minimum height of 100vh */
    display: flex;
    right: 0;
    padding: 20px; /* Add padding for spacing */
   


}


@keyframes slideshow {
    0% {
        background-image: url('../../assets/fbg2.jpg');
    }
    20% {
        background-image: url('../../assets/f6.jpg');
    }
    40% {
        background-image: url('../../assets/f0.jpg');
    }
    60% {
        background-image: url('../../assets/f7.webp');
    }
    80% {
        background-image: url('../../assets/f4.avif');
    }
    100% {
        background-image: url('../../assets/f5.webp') /* Loop back to the first image */
    }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .navBlock {
        padding: 10px; /* Adjust padding for smaller devices */
    }
}


.contact{
    margin-top: 10%;
}

