/* Navbar.css */
.bg-cover {
    padding: 10px 0;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6); */
    /* border: 10px solid red; */
    width: 100%;
    height: 20vh; /* Change height to auto for responsiveness */
}

.navbar-container {
    display: flex;
    align-items: center;
    height: 20%;
    width: 100%;
    margin-right: 20px; 
    position: absolute;
    
}

.container{
    /* border: 3pt solid red; */
    margin-top: -7%;
}

.navbar-links {
    display: flex;
    justify-content: flex-end; /* Align links to the end (right side) */
    align-items: center;
    margin-left: 60%;
}

.navbar-link {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    margin-left: 20px;
    font-family: "Gill Sans", sans-serif;
    transition: transform 0.3s ease; /* Added transition property */
    /* border: 3pt solid black; */
    padding: 3px;
}

.navbar-link:hover {
    transform: scale(1.6); /* Enlarge the link on hover */
    color: rgb(48, 104, 187);
}

/* .navbar-link:hover {
    transform: scale(1.75);
    color: rgb(48, 104, 187);
} */

.logo img{
    width: 10%;
    height: 10%;
    margin-left: -5%;
}
  
